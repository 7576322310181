import { Suspense, useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Stack,
  Image,
  Modal,
} from "react-bootstrap";
import CarrefourImage from "../assets/images/carrefour.png";
import AbrilImbativelSmall from "../assets/images/abril-imbativel-small.jpg";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import useApi from "../hooks/useApi";

export function Component() {
  const navigate = useNavigate();
  const location = useLocation();

  const app = useContext(AppContext);
  const api = useApi();

  const [isCopied, setIsCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [descontos, setDescontos] = useState<any[]>([]);
  const [desconto, setDesconto] = useState<any>();

  const handleClose = () => setDesconto(null);

  const handleSelect = (desconto: any) => () => {
    setDesconto(desconto);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(desconto?.voucher ?? "");
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 5000);
  };

  const handleSuccess = (response: any) => {
    setIsLoading(false);

    const data = response.data.map((item: any) => ({
      ...item,
      link:
        item.tipo === "Físico"
          ? ""
          : item.tipo === "Ecommerce Mercado"
            ? "https://mercado.carrefour.com.br"
            : "https://www.carrefour.com.br",
      imagem: `/assets/images/discounts/${item.slug}.jpg`,
    }));

    setDescontos(data);
  };

  const handleError = () => {
    setIsLoading(false);
    setDescontos([]);
  };

  const handleImageError = (e: any) => {
    if (e.target.src !== CarrefourImage) {
      e.target.src = CarrefourImage;
    }
  };

  useEffect(() => {
    api.getDiscounts().then(handleSuccess).catch(handleError);
  }, [api]);

  if (!app.isAuthenticated) {
    return <Navigate to="/login" state={{ location }} replace />;
  }

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">MEUS DESCONTOS</h1>

          <Row className="mb-4">
            <Col xs={12} md={8}>
              <h2 className="fs-4">Olá, {app.user.nome}</h2>
              <p>Você possui {descontos.length} cupons de desconto.</p>
            </Col>
          </Row>

          <Stack
            direction="horizontal"
            className="flex-column flex-md-row justify-content-between"
          >
            <Button
              variant="light"
              className="mb-4"
              onClick={() => navigate("/meus-numeros")}
            >
              <i className="bi bi-ticket me-2"></i>
              Ver meus números
            </Button>

            <a
              title="Abril Imbatível Carrefour"
              href="https://carrinhocheiooanointeiro.com.br/?&utm_source=carrinho-cheio&utm_campaign=abril-imbativel&utm_term=banner-site"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Image
                src={AbrilImbativelSmall}
                alt="Banner Abril Imbatível"
                className="mb-3"
                width={300}
              />
            </a>
          </Stack>

          <Row>
            {isLoading && <p>Carregando descontos...</p>}

            {!isLoading && descontos.length === 0 && (
              <p>Nenhum desconto disponível.</p>
            )}

            {!isLoading &&
              descontos.map((desconto) => (
                <Col key={desconto.id} xs={12} md={6} className="mb-4">
                  <Card
                    className="discount-card"
                    onClick={handleSelect(desconto)}
                  >
                    <Card.Body>
                      <Row>
                        <Col xs={3} className="d-flex">
                          <Image
                            src={desconto.imagem}
                            alt={`Desconto ${desconto.categoria}`}
                            onError={handleImageError}
                            className="discount-card__image"
                          />
                        </Col>
                        <Col>
                          <Card.Text>
                            <b>{desconto.categoria}</b>
                          </Card.Text>
                          {desconto.dinamica && (
                            <Card.Text>{desconto.dinamica}</Card.Text>
                          )}
                          {desconto.limite && (
                            <Card.Text>Limitado a {desconto.limite}</Card.Text>
                          )}
                          {desconto.tipo && (
                            <Card.Text>
                              Apenas em{" "}
                              {desconto.tipo === "Físico"
                                ? "lojas físicas"
                                : desconto.tipo.toLowerCase()}
                            </Card.Text>
                          )}
                          <Card.Text className="text-small">
                            <small>
                              Utilize o seu cupom na loja{" "}
                              {desconto.tipo === "Físico"
                                ? "física após 72h"
                                : "online"}
                              <br />
                              Consulte seus descontos na página Meu Extrato
                            </small>
                          </Card.Text>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer className="d-flex">
                      <Button
                        variant="primary"
                        className="mx-auto"
                        size="sm"
                        onClick={handleSelect(desconto)}
                      >
                        <i className="bi bi-info-circle-fill me-2"></i>
                        <span>Ver detalhes</span>
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
          </Row>
        </Container>
      </section>
      <Modal size="lg" show={!!desconto} onHide={handleClose}>
        <Modal.Body className="d-flex flex-column">
          <Row>
            <Col xs={4}>
              <Image
                src={desconto?.imagem}
                alt={`Desconto ${desconto?.categoria}`}
                onError={handleImageError}
                fluid
              />
            </Col>
            <Col>
              {desconto?.categoria && <h2 className="fs-5">{desconto?.categoria}</h2>}

              <p className="">
                {desconto?.dinamica && (
                  <span className="d-block">{desconto?.dinamica}</span>
                )}
                {desconto?.limite && (
                  <span className="d-block">Limitado a ${desconto?.limite}</span>
                )}
                <span className="d-block mt-4">Válido somente para o mês de Abril de 2024</span>
                {desconto?.tipo && (
                  <span className="d-block">Apenas em {desconto?.tipo === "Físico" ? "lojas físicas" : desconto?.tipo.toLowerCase()}</span>
                )}
              </p>

              {desconto?.tipo && (
                <p className="s">
                  <span className="d-block">Como resgatar:</span>
                  {desconto?.tipo === "Físico" ? (
                    <ul>
                      <li>
                        Desconto vinculado ao seu CPF nas compras em lojas físicas.
                      </li>
                    </ul>
                  ) : (
                    <ul>
                      <li>
                        Acesse o site{" "}
                        <a
                          href={desconto?.link}
                          className="text-primary"
                          rel="noopener noreferrer nofollow"
                          target="_blank"
                        >
                          {desconto?.link}
                        </a>
                      </li>
                      <li>Use o código e resgate seu prêmio.</li>
                    </ul>
                  )}
                </p>
              )}

              <i className="text-center text-small mb-3">
                CONSULTE CONDIÇÕES E DISPONIBILIDADE NO SITE E/OU NA LOJA
              </i>

              {desconto?.voucher && (
                <>
                  <p className="mt-3">Código:</p>
                  <Stack direction="horizontal" gap={2}>
                    <span className="text-break text-dark bg-light border p-1">
                      {desconto?.voucher}
                    </span>
                    <Button
                      size="sm"
                      variant="primary"
                      className="ms-2 button"
                      onClick={handleCopy}
                    >
                      <i className="bi bi-clipboard me-1"></i>
                      <span>{isCopied ? "Copiado!" : "Copiar"}</span>
                    </Button>
                  </Stack>
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleClose}
            className="mx-auto button"
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </Suspense>
  );
}
